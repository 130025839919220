define("discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "select-kit/components/category-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _categoryChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupListInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    constructor() {
      super(...arguments);
      this.value = this.args.field.value;
    }
    update(id1) {
      this.value = id1;
      this.args.field.set(id1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "update", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@field.Custom id={{@field.id}}>
          <CategoryChooser
            @value={{this.value}}
            @onChange={{this.update}}
            name={{@info.identifier}}
          />
        </@field.Custom>
      
    */
    {
      "id": "lt6T57Dz",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Custom\"]],[[16,1,[30,1,[\"id\"]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[32,0],[[16,3,[30,2,[\"identifier\"]]]],[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"update\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@field\",\"@info\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input.js",
      "scope": () => [_categoryChooser.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GroupListInput;
});